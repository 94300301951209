import myApi from "@/api/myApi";

export default {
  namespaced: true,
  state: {
    client: null,
    cylinders: [],
    client_phone: "",
    phone: "",
    dictionary: {},
  },
  mutations: {
    setClient(state, data) {
      state.client = data;
    },
    setClientPhone(state, data) {
      state.client_phone = data;
    },
    addCylinder(state, data) {
      state.cylinders.push(data);
    },
    updateCylinder(state, data) {
      state.cylinders[data.key] = data.value;
    },
    setPhone(state, data) {
      state.phone = data;
    },
    reset(state) {
      state.client = null;
      state.client_phone = null;
      state.cylinders = [];
      state.phone = null;
    },
  },
  getters: {},
  actions: {
    createService({ state, rootState }) {
      return new Promise((resolve, reject) => {
        myApi
          .postService({
            customer_id: state.client.id,
            user_id: rootState.user_id,
            phone: state.client_phone,
            location_id: rootState.location_id,
            notification_phone: state.phone,
            items: state.cylinders,
          })
          .then(
            (r) => resolve(r),
            (error) => reject(error)
          );
      });
    },
    loadDictionary({ state }) {
      return new Promise((resolve) => {
        myApi.getServiceDictionary().then((r) => {
          state.dictionary = r.data;
          resolve(r);
        });
      });
    },
    reset: function ({ commit }) {
      commit("reset");
    },
  },
};
