<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <div
      class="w-100 d-flex justify-content-between align-items-center"
      style="background: #dadada"
    >
      <router-link
        to="/"
        class="btn btn-lg btn-secondary m-2"
        ><i class="bi bi-chevron-left"></i
      ></router-link>
      <h2 class="flex-fill m-0">Serwis</h2>
    </div>
    <div
      v-if="loading"
      class="text-center"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div
      class="w-100 p-2 pb-5 container flex-grow-1 d-flex align-items-start flex-column justify-content-start text-start"
      style="overflow: auto"
    >
      <div class="row px-3 col-md-12">
        <div class="col col-6 p-1">
          <router-link
            to="/service/list"
            class="d-block btn-menu bg-primary rounded-3 text-white text-center p-3 position-relative"
          >
            <img
              src="@/assets/menu/orders.svg"
              class="my-2"
            />

            <span class="w-100 d-block text-center fs-4"> Zlecenia </span>
          </router-link>
        </div>

        <div class="col col-6 p-1">
          <router-link
            to="/service/add"
            class="d-block btn-menu bg-primary rounded-3 text-white text-center p-3 position-relative"
          >
            <img
              src="@/assets/menu/pickup.svg"
              class="my-2"
            />

            <span class="w-100 d-block text-center fs-4"> Przyjmij </span>
          </router-link>
        </div>
        <div class="col col-6 p-1">
          <router-link
            to="/service/todo"
            class="d-block btn-menu bg-primary rounded-3 text-white text-center p-3 position-relative"
          >
            <img
              src="@/assets/menu/service.svg"
              class="my-2"
            />

            <span class="w-100 d-block text-center fs-4"> Wykonaj </span>
          </router-link>
        </div>
        <div class="col col-6 p-1">
          <router-link
            to="/service/ready"
            class="d-block btn-menu bg-primary rounded-3 text-white text-center p-3 position-relative"
          >
            <img
              src="@/assets/menu/disloading.svg"
              class="my-2"
            />

            <span class="w-100 d-block text-center fs-4"> Wydaj </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import myApi from "@/api/myApi";

export default {
  name: "SimpleaddIndex",
  components: {},
  data: function () {
    return {
      selectedOrder: {},
      id: 0,
      products: [],
      filter: "",
      serial: "",
      date: "",
      loading: false,
      showAll: false,
      last: [],
    };
  },
  props: {},
  computed: {
    product: function () {
      return this.$store.state.simpleadd.product;
    },
  },
  methods: {},
  created() {},
  mounted() {
    const data = localStorage.getItem("last_simpleadd");
    if (data) {
      this.last = JSON.parse(data) ?? [];
    }

    // myApi.getAllService().then((response) => {
    //   this.last = response.data.data;
    // });
  },
};
</script>
