<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <div
      class="w-100 d-flex justify-content-between align-items-center"
      style="background: #dadada"
    >
      <router-link
        to="/service/add"
        class="btn btn-lg btn-secondary m-2"
        ><i class="bi bi-chevron-left"></i
      ></router-link>
      <h2 class="flex-fill m-0">{{ $route.params.id ? "Edytuj butlę" : "Dodaj butlę" }}</h2>
      <button
        type="button"
        @click="add"
        class="btn btn-lg btn-success flex-fill m-2"
      >
        {{ $route.params.id ? "Zapisz" : "Dodaj" }}
      </button>
    </div>
    <div
      v-if="loading"
      class="text-center"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div
      class="w-100 p-2 pb-5 container flex-grow-1 d-flex align-items-start flex-column justify-content-start text-start"
      style="overflow: auto"
    >
      <div class="w-100">
        <div class="form-group mb-3">
          <label class="form-label">Nr butli/naklejki</label>
          <div class="input-group">
            <input
              :disabled="downloadedCode"
              v-model="item.cylinder_qr_code"
              type="text"
              class="form-control form-control-lg"
            />
            <button
              v-if="downloadedCode"
              @click.prevent="(item.cylinder_qr_code = ''), (downloadedCode = false)"
              class="btn btn-outline-secondary d-flex align-items-center"
            >
              <span class="material-icons"> close </span>
            </button>
            <button
             v-if="!downloadedCode"
              @click.prevent="findNumber"
              class="btn btn-outline-secondary d-flex align-items-center"
            >
             Pobierz następny
            </button>
          </div>
        </div>

        <div class="form-group mb-3">
          <label class="form-label">Nr seryjny</label>
          <div class="input-group">
            <input
              v-model="item.cylinder_serial_number"
              type="text"
              class="form-control"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group mb-3">
              <label class="form-label">Typ gazu</label>
              <div class="input-group">
                <select
                  class="form-select"
                  v-model="item.cylinder_working_factor"
                >
                  <option value=""></option>
                  <option value="tlen">Tlen</option>
                  <option value="azot">Azot</option>
                  <option value="argon">Argon</option>
                  <option value="co2">CO2</option>
                  <option value="arco2">Ar+Co2</option>
                  <option value="powietrze">Powietrze</option>
                  <option value="wodor">Wodór</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group mb-3">
              <label class="form-label">Rok produkcji</label>
              <div class="input-group">
                <select
                  class="form-select"
                  v-model="item.cylinder_production_date"
                >
                  <option
                    v-for="y in 80"
                    :key="y"
                  >
                    {{ year - y }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group mb-3">
              <label class="form-label">Pojemność butli</label>
              <div class="input-group">
                <input
                  type="number"
                  v-model="item.cylinder_capacity"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group mb-3">
              <label class="form-label">Waga butli</label>
              <div class="input-group">
                <input
                  v-model="item.cylinder_weight"
                  type="number"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group mb-3">
              <label class="form-label">Opis dodatkowy</label>
              <div class="input-group">
                <textarea
                  v-model="item.cylinder_description"
                  type="number"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <label>Usługi do wykonania</label>
          <div
            v-for="(type, key) in dictionary.service_types"
            :key="key"
          >
            <MagicoCheckbox
              v-model="item.service_type"
              custom-class="form-control-lg mb-0"
              :value="key"
              >{{ type }}</MagicoCheckbox
            >
          </div>
        </div>
      </div>
    </div>
    <KeyboardScanner
      @input="submitCode"
      :listen="true && !downloadedCode"
    ></KeyboardScanner>
  </div>
</template>
<script>
import myApi from "@/api/myApi";
import KeyboardScanner from "../../components/KeyboardScanner.vue";

import dayjs from "dayjs";
import MagicoCheckbox from "@/components/MagicoCheckbox.vue";

export default {
  name: "SimpleaddIndex",
  components: { KeyboardScanner, MagicoCheckbox },
  data: function () {
    return {
      selectedOrder: {},
      id: 0,
      products: [],
      filter: "",
      downloadedCode: false,
      loading: false,

      showAll: false,
      item: {
        cylinder_id: 0,
        cylinder_production_date: "",
        cylinder_serial_number: "",
        cylinder_working_factor: "",
        cylinder_capacity: "",
        cylinder_pressure_test: "",
        cylinder_weight: "",
        cylinder_weight_real: "",
        cylinder_pice: "",
        cylinder_qr_code: "",
        service_type: [],
      },
      last: [],
      year: parseInt(dayjs().format("YYYY")),
    };
  },
  props: {},

  computed: {
    product: function () {
      return this.$store.state.simpleadd.product;
    },
    dictionary: function () {
      return this.$store.state.service.dictionary;
    },
  },
  methods: {
    submitCode: function (code) {
      this.item.cylinder_qr_code = code;
      window.navigator?.vibrate?.([100, 50, 200, 50, 100, 50]);
      new Audio("/beep1.mp3").play();
    },
    findNumber: function () {
      this.downloadedCode = true;
      myApi.getServiceCylinderNumber().then((response) => {
        this.item.cylinder_qr_code = response.data.data.cylinder_qr_code;
      });
    },
    add: function () {
      if (!this.item.cylinder_qr_code) {
        this.$swal("Błąd", "Numer butli wymagany!", "error");
        return;
      }
      if (this.item.service_type.length == 0) {
        this.$swal("Błąd", "Typ usługi wymagany!", "error");
        return;
      }
      if (this.$route.params.id) {
        this.$store.commit("service/updateCylinder", {
          key: this.$route.params.id,
          value: this.item,
        });
      } else {
        this.$store.commit("service/addCylinder", this.item);
      }

      this.$router.push("/service/add");
    },
  },
  created() {},
  mounted() {
    if (this.$route.params.id) {
      this.item = this.$store.state.service.cylinders[this.$route.params.id];
    }
  },
};
</script>
