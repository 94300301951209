<template>
  <span
    :class="[
      'badge rounded-pill text-white',
      'badge-status-' + status,
    ]"
  >
    {{ $store.state.service.dictionary.statuses[status] ?? "---" }}
  </span>
</template>

<script>
export default {
  props: ["status"],
};
</script>
<style>
.badge-status-open{
  background-color: rgb(221, 125, 80);
}
.badge-status-ready{
  background-color: rgba(45, 126, 13, 0.836);
}
.badge-status-done{
  background-color: rgb(80, 87, 190);
}
.badge-status-prog{
  background-color: rgb(255, 219, 14);
}
</style>