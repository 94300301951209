<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <div
      class="w-100 d-flex justify-content-between align-items-center"
      style="background: #dadada"
    >
      <router-link
        to="/"
        class="btn btn-lg btn-secondary m-2"
        ><i class="bi bi-house"></i
      ></router-link>
      <h2 class="flex-fill m-0">Podgląd butli {{ cylinder.cylinder_qr_code }}</h2>
      <button
        v-if="printer_id"
        :disabled="printDisabled"
        class="btn btn-lg btn-primary me-2"
        @click.prevent="print"
      >
        <i class="bi bi-printer"></i>
      </button>
    </div>

    <div
      v-if="!notFound"
      class="w-100 p-2 pb-5"
      style="overflow: auto; flex-grow: 2"
    >
      <CylinderInfo :cylinder="cylinder"></CylinderInfo>

      <h3>Ostanie logi</h3>
      <CylinderLogs
        :cylinder_id="cylinder.cylinder_id"
        limit="5"
      ></CylinderLogs>
    </div>
    <div
      v-if="notFound"
      class="w-100 p-2 pb-5 text-center mt-5"
      style="overflow: auto; flex-grow: 2"
    >
      <div>
        <i class="fs-1 text-danger bi bi-exclamation-diamond-fill"></i>
      </div>
      <h2>Butla o kodzie {{ $route.params.code }} nie została znaleziona</h2>

      <router-link
        to="/cylinder/search"
        class="btn btn-outline-secondary mt-4"
        >Wróć</router-link
      >
    </div>
    <KeyboardScanner
      :listen="true"
      @input="searchCylinder"
    ></KeyboardScanner>
  </div>
</template>

<script>
import CylinderInfo from "@/components/CylinderInfo.vue";
import CylinderLogs from "@/components/CylinderLogs.vue";
import KeyboardScanner from "@/components/KeyboardScanner.vue";
import api from "@/api/myApi";

export default {
  name: "CylinderSearch",
  data: function () {
    return {
      pin: "",
      cylinder: {},
      notFound: false,
      printDisabled: false,
    };
  },
  computed: {
    printer_id: function () {
      return localStorage.getItem("printer_id") ?? null;
    },
  },
  methods: {
    modalOk: function () {},

    searchCylinder: function (code) {
      this.$router.push("/cylinder/qrcode/" + code);
    },

    print: function () {
      let vm = this;
      this.$swal
        .fire({
          title: "Drukowanie etykiety",
          text: "Domyślnie drukowana jest 1 etykieta",
          input: "select",
          inputOptions: {
            1: 1,
            2: 2,
            3: 3,
          },
          inputPlaceholder: "Ilość etykiet (1)",
          showCancelButton: true,
          cancelButtonText: "Anuluj",
        })
        .then((r) => {
          console.log(r);
          if (r.isConfirmed) {
            for (let i = 0; i < (r.value ? parseInt(r.value) : 1); i++) {
              api.printLabels(vm.cylinder.cylinder_id, vm.printer_id).then(
                () => {
                  console.log("print_success");
                },
                () => {
                  vm.$swal({
                    icons: "error",
                    title: "Błąd drukarki",
                    text: "Nie można było dodać zadania do kolejki druku",
                  });
                }
              );
            }
            vm.printDisabled = true;
            setTimeout(function () {
              vm.printDisabled = false;
            }, 10000);
            vm.$swal({
              icons: "success",
              title: "Wysłano do druku",
              text: "Drukarka w ciągu kilku sekund wydrukuje twoją etykietę",
            });
          }
        });
    },
  },

  mounted: function () {
    this.$store.dispatch("storage/findCylinder", this.$route.params.code).then(
      (cylinder) => {
        this.cylinder = cylinder;
        if (cylinder.service) {
          this.$store.dispatch("service/loadDictionary");
        }
      },
      () => {
        this.notFound = true;
      }
    );
  },
  components: { CylinderInfo, CylinderLogs, KeyboardScanner },
};
</script>
