<template>
  <label
    class="form-check"
    :class="customClass"
  >
    <input
      class="form-check-input"
      type="checkbox"
      :id="id"
      :checked="isChecked"
      :value="value"
      @change="updateInput"
    />
    <label
      class="form-check-label"
      :for="id"
    >
      <slot></slot>
    </label>
  </label>
</template>

<script>
export default {
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    customClass: { default: "" },
    value: {},
    modelValue: { default: "" },
    trueValue: { default: true },
    falseValue: { default: false },
  },
  data: function () {
    return {
      id: "chckm" + Math.round(Math.random() * 1000000),
    };
  },
  computed: {
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.trueValue;
    },
  },
  methods: {
    updateInput(event) {
      let isChecked = event.target.checked;
      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue];
        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }
        this.$emit("change", newValue);
      } else {
        this.$emit("change", isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
</script>
