<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <div
      class="w-100 d-flex justify-content-end align-items-center"
      style="background: #0d6efd; color: #fff"
    >
      <router-link
        to="/menu"
        class="btn btn-lg btn-success m-2"
        ><i class="bi bi-x-lg"></i
      ></router-link>
    </div>
    <div
      class="w-100 p-2 pb-5 flex-grow-1"
      style="overflow: auto"
    >
      <h4 class="pb-2">
        Magazyn: {{ locationName ? locationName : "[WYBIERZ MAGAZYN]"
        }}<button
          @click="showLista = !showLista"
          class="btn btn-outline-secondary float-end pull-end"
        >
          Zmień
        </button>
      </h4>
      <ul
        class="list-group"
        v-if="showLista"
      >
        <li
          v-for="item in storages"
          class="list-group-item"
          :key="item.location_id"
        >
          {{ item.location_name }}
          <button
            @click="wybierz(item)"
            class="btn btn-outline-primary float-end pull-end"
          >
            Wybierz
          </button>
        </li>
      </ul>

      <MagicoCheckbox
        @change="save"
        v-model="production_available"
        >Kafelek produkcji</MagicoCheckbox
      >
      <MagicoCheckbox
        @change="save"
        v-model="legalization_available"
        >Kafelek procesu legalizacji</MagicoCheckbox
      >
      <MagicoCheckbox
        @change="save"
        v-model="cylinderadd_tile"
        >Kafelek dodawania butli</MagicoCheckbox
      >
      <MagicoCheckbox
        @change="save"
        v-model="service_tile"
        >Kafelek obsługi legalizacji butli obcych</MagicoCheckbox
      >
      <div>
        <label>ID drukarki</label>
        <input
          @change="save"
          type="text"
          v-model="printer_id"
          class="form-control"
        />
      </div>
      <div>
        <label>Automtycznie wylogowanie po minutach:</label>
        <input
          @change="save"
          type="text"
          v-model="autologoff"
          class="form-control"
        />
      </div>
    </div>
  </div>
</template>
<script>
import myApi from "@/api/myApi";
import MagicoCheckbox from "@/components/MagicoCheckbox.vue";

export default {
  name: "SettingsView",
  components: { MagicoCheckbox },
  props: {},
  computed: {
    locationName() {
      return this.$store.state.location_name;
    },
  },
  data: function () {
    return {
      storages: [],
      showLista: false,
      production_available: false,
      legalization_available: false,
      service_tile:false,
      cylinderadd_tile: false,
      printer_id: 0,
      autologoff: '30',
    };
  },
  methods: {
    save: function () {
      localStorage.setItem("production_tile", this.production_available ? 1 : 0);
      localStorage.setItem("legalization_tile", this.legalization_available ? 1 : 0);
      localStorage.setItem("cylinderadd_tile", this.cylinderadd_tile ? 1 : 0);
      localStorage.setItem("service_tile", this.service_tile ? 1 : 0);

      localStorage.setItem("printer_id", this.printer_id);
      localStorage.setItem("autologoff", this.autologoff);
    },
    wybierz(location) {
      this.$store.commit("changeLocation", location);
      // this.$store.commit('setPojazdId', location.magazyn_id);
      // this.$store.commit('setLocationId', location.location_id);
      // this.$store.commit('setLocationName', location.location_name);
      this.showLista = false;
    },
    loadStorages: function () {
      myApi.findStorages("magazyn").then(
        (response) => {
          this.storages = response.data;
        },
        (e) => {
          console.log("storages", e);
          // alert('nie można porabć magazynów')
        }
      );
    },
  },
  created() {
    this.loadStorages();
    this.showLista = false;
    this.production_available = localStorage.getItem("production_tile") == 1 ? true : false;
    this.legalization_available = localStorage.getItem("legalization_tile") == 1 ? true : false;
    this.cylinderadd_tile = localStorage.getItem("cylinderadd_tile") == 1 ? true : false;
    this.service_tile = localStorage.getItem("service_tile") == 1 ? true : false;
    this.printer_id = localStorage.getItem("printer_id") ?? "";
    this.autologoff = localStorage.getItem("autologoff") ?? "20";
  },
  mounted() {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
