import Vue from "vue";

export default {
  findLocationStorageState(location_id) {
    return Vue.axios.get("blueapp/location/" + location_id + "/storage");
  },
  getLocationStorage() {
    return Vue.axios.get("blueapp/location/storages/auto");
  },
  getCylinderDb(params = {}) {
    return Vue.axios.get("blueapp/cylinder/minimal", { params: params });
  },
  getCylinderAll(params = {}) {
    return Vue.axios.get("blueapp/cylinder", { params: params });
  },
  findStorages(type, locationFrom = null) {
    return Vue.axios.get(
      "blueapp/location/storages/" + type + (locationFrom ? "?locationFromId=" + locationFrom : "")
    );
  },
  getProductsDictionary() {
    return Vue.axios.get("blueapp/product");
  },
  getMe: function () {
    return Vue.axios.get("blueapp/me");
  },
  getKierowcaByPin: function (pin) {
    return Vue.axios.post("blueapp/pin", { pin: pin });
  },
  getKartaDrogowa: function (kierowca_id) {
    return Vue.axios.get("blueapp/kierowca/" + kierowca_id + "/kartadrogowa");
  },
  findCylinder(qrcode) {
    return Vue.axios.get("blueapp/cylinder/qrcode/" + qrcode);
  },
  getCylinder(id) {
    return Vue.axios.get("blueapp/cylinder/" + id);
  },
  getCylinderLogs(cylinder_id, limit = 5) {
    return Vue.axios.get("/blueapp/cylinder/" + cylinder_id + "/log?limit=" + limit);
  },
  getOrders: function (locationId) {
    return Vue.axios.get("blueapp/order/location/" + locationId);
  },
  getTakein: function (locationId) {
    return Vue.axios.get("blueapp/order/location/" + locationId + "/takein");
  },
  doTakein: function (locationId, data) {
    return Vue.axios.post("blueapp/order/location/" + locationId + "/takein", data);
  },
  patchOrder(data) {
    return Vue.axios.post("blueapp/order/summary", data);
  },
  closeOrder(data) {
    return Vue.axios.post("blueapp/order/closeorder", data);
  },
  postPickup(location_id, data) {
    return Vue.axios.post("blueapp/location/" + location_id + "/pickup", data);
  },
  postProduction(location_id, data) {
    return Vue.axios.post("blueapp/location/" + location_id + "/fillup", data);
  },
  getCylinderComplaint(cylinder_id) {
    return Vue.axios.get("blueapp/cylinder/" + cylinder_id + "/complaint");
  },
  findComplaint(complaint_number) {
    return Vue.axios.get("blueapp/complaint/find/" + complaint_number + "");
  },
  postResolveComplaint(complaint_number, data) {
    return Vue.axios.post("blueapp/complaint/" + complaint_number + "/resolve", data);
  },
  getAllLegalization() {
    return Vue.axios.get("blueapp/legalization");
  },
  getLegalization(id) {
    return Vue.axios.get("blueapp/legalization/" + id);
  },
  getAllLegalizationItem(id) {
    return Vue.axios.get("blueapp/legalization/" + id + "/item");
  },
  getLegalizationItem(id, item_id) {
    return Vue.axios.get("blueapp/legalization/" + id + "/item/" + item_id);
  },
  postLegalizationCylinder(legalisation_id, data) {
    return Vue.axios.post("blueapp/legalization/" + legalisation_id + "/item", data);
  },
  putLegalizationCylinder(legalisation_id, item_id, data) {
    return Vue.axios.put("blueapp/legalization/" + legalisation_id + "/item/" + item_id, data);
  },
  deleteLegalizationCylinder(legalisation_id, item_id) {
    return Vue.axios.delete("blueapp/legalization/" + legalisation_id + "/item/" + item_id);
  },
  getRandomWeight() {
    return Vue.axios.get("blueapp/scale/1");
  },
  postLegalizationAlienCylinder(legalisation_id, data) {
    return Vue.axios.post("blueapp/legalization/" + legalisation_id + "/alien", data);
  },
  putLegalizationAlienCylinder(legalisation_id, item_id, data) {
    return Vue.axios.put("blueapp/legalization/" + legalisation_id + "/alien/" + item_id, data);
  },

  postLegalization(data) {
    return Vue.axios.post("blueapp/legalization", data);
  },
  putLegalization(legalisation_id, data) {
    return Vue.axios.put("blueapp/legalization/" + legalisation_id, data);
  },
  closeLegalization(legalisation_id, data) {
    return Vue.axios.post("blueapp/legalization/" + legalisation_id + "/close", data);
  },
  getCylinderWorkingFactor() {
    return Vue.axios.get("/blueapp/slownik/cylinder_working_factor");
  },
  cancelLegalization(legalisation_id) {
    return Vue.axios.delete("blueapp/legalization/" + legalisation_id + "");
  },
  postSimpleadd(data) {
    return Vue.axios.post("blueapp/simplecylinder", data);
  },
  printLabels(cylinder_id, printer_id) {
    return Vue.axios.get("blueapp/simplecylinder/" + cylinder_id + "/print/" + printer_id);
  },

  /* CERTIFICATION */

  getAllService(params = null) {
    return Vue.axios.get("blueapp/service", { params });
  },
  getService(id, params = null) {
    return Vue.axios.get("blueapp/service/" + id, { params });
  },
  postService(data, params = null) {
    return Vue.axios.post("blueapp/service", data, { params });
  },
  getAllCustomer(params = null) {
    return Vue.axios.get("blueapp/customer", { params: params });
  },
  postCustomer(data, params = null) {
    return Vue.axios.post("blueapp/customer", data, { params: params });
  },
  getServiceDictionary(params = null) {
    return Vue.axios.get("blueapp/service/dictionary", { params: params });
  },

  getServiceCylinderNumber(params = null) {
    return Vue.axios.get("blueapp/service/findnumber", { params: params });
  },
  printServiceOrderLabels(id, printer_id) {
    return Vue.axios.post("blueapp/service/order/" + id + "/print/" + printer_id);
  },
  putServiceReady(id, data) {
    return Vue.axios.put("blueapp/service/" + id + "/ready", data);
  },
  putServiceDone(id, data) {
    return Vue.axios.put("blueapp/service/" + id + "/done", data);
  },
};
