<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <UpdateNotifier></UpdateNotifier>
    <div
      class="w-100 d-flex justify-content-end align-items-center"
      style="background: #0d6efd; color: #fff"
      @click="navbar = !navbar"
      :style="!navbar ? 'height:25px' : 'height:auto'"
    >
      <router-link
        v-show="navbar"
        to="/menu"
        class="btn btn-lg btn-primary m-2"
        ><i class="bi bi-three-dots-vertical"></i
      ></router-link>
    </div>

    <div
      class="w-100 p-2"
      style="overflow: auto; flex-grow: 2"
    >
      <div class="container mt-1">
        <div class="row gy-1">
          <div class="col col-6 p-1">
            <router-link
              to="/pickup"
              class="d-block btn-menu bg-primary rounded-3 text-white text-center p-3 position-relative"
            >
              <img
                src="@/assets/menu/pickup.svg"
                class="my-2"
              />
              <span
                v-if="$store.state.pickup.locationFrom"
                style="position: absolute; top: 10px; right: 10px"
                class="badge bg-danger"
                >w toku</span
              >
              <span class="w-100 d-block text-center fs-4"> Przyjęcie </span>
            </router-link>
          </div>

          <div
            class="col col-6 p-1"
            v-if="productionTile"
          >
            <router-link
              to="/production"
              class="d-block bg-primary rounded-3 btn-menu text-white text-center p-3 position-relative"
            >
              <img
                src="@/assets/menu/production.svg"
                class="my-2"
              />

              <span
                v-if="$store.state.production.products.length > 0"
                style="position: absolute; top: 10px; right: 10px"
                class="badge bg-danger"
                >w toku</span
              >
              <span class="w-100 d-block text-center fs-4"> Produkcja </span>
            </router-link>
          </div>
          <div class="col col-6 p-1">
            <router-link
              to="/storage"
              class="d-block btn-menu bg-primary rounded-3 text-white text-center p-3"
            >
              <img
                src="@/assets/menu/storage.svg"
                class="my-2"
              />

              <span class="w-100 d-block text-center text-nowrap fs-5"> Stan magazynu </span>
            </router-link>
          </div>

          <div class="col col-6 p-1 d-none">
            <router-link
              to="/disloading"
              class="d-block bg-primary rounded-3 btn-menu text-white text-center p-3 position-relative"
            >
              <img
                src="@/assets/menu/disloading.svg"
                class="my-2"
              />
              <span
                v-if="$store.state.disloading.order"
                style="position: absolute; top: 10px; right: 10px"
                class="badge bg-danger"
                >w toku</span
              >
              <span class="w-100 d-block text-center fs-4"> Wydanie do klienta </span>
            </router-link>
          </div>

          <div class="col col-6 p-1 d-none">
            <router-link
              to="/pickup"
              class="d-block btn-menu bg-primary rounded-3 text-white text-center p-3 position-relative"
            >
              <span
                v-if="$store.state.pickup.order"
                style="position: absolute; top: 10px; right: 10px"
                class="badge bg-danger"
                >w toku</span
              >

              <span class="w-100 d-block text-center fs-4"> Odbiór od klienta </span>
            </router-link>
          </div>

          <div class="col col-6 p-1">
            <router-link
              to="/orders"
              class="d-block btn-menu bg-primary rounded-3 text-white text-center p-3"
            >
              <img
                src="@/assets/menu/orders.svg"
                class="my-2"
              />
              <span class="w-100 d-block text-center fs-4"> Zamówienia </span>
            </router-link>
          </div>

          <div class="col col-6 p-1">
            <router-link
              to="/cylinder/search"
              class="d-block bg-primary btn-menu rounded-3 text-white text-center p-3"
            >
              <img
                src="@/assets/menu/cylinder.svg"
                class="my-2"
              />

              <span class="w-100 d-block text-center fs-4"> Butle </span>
            </router-link>
          </div>
          <div class="col col-6 p-1">
            <router-link
              to="/complaint/search"
              class="d-block bg-primary btn-menu rounded-3 text-white text-center p-3"
            >
              <img
                src="@/assets/menu/complaint.svg"
                class="my-2"
              />

              <span class="w-100 d-block text-center fs-4"> Reklamacje </span>
            </router-link>
          </div>
          <div
            class="col col-6 p-1"
            v-if="legalizationTile"
          >
            <router-link
              to="/legalization"
              class="d-block bg-primary btn-menu rounded-3 text-white text-center p-3"
            >
              <img
                src="@/assets/menu/legalization.svg"
                class="my-2"
              />
              <span class="w-100 d-block text-center fs-4"> Legalizacja </span>
            </router-link>
          </div>
          <div
            class="col col-6 p-1"
            v-if="serviceTile"
          >
            <router-link
              to="/service"
              class="d-block bg-primary btn-menu rounded-3 text-white text-center p-3"
            >
              <img
                src="@/assets/menu/service.svg"
                class="my-2"
              />

              <span class="w-100 d-block text-center fs-4"> Serwis </span>
            </router-link>
          </div>
          <div
            class="col col-6 p-1"
            v-if="cylinderaddTile"
          >
            <router-link
              to="/simpleadd"
              class="d-block bg-primary btn-menu rounded-3 text-white text-center p-3"
            >
              <img
                src="@/assets/menu/simpleadd.svg"
                class="my-2"
              />
              <span class="w-100 d-block text-center fs-4"> Dodaj butlę </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-0 pb-0 w-100 text-center">
      <div
        class="container-fluid text-center w-100 py-1"
        style="background-color: #eaeaea"
      >
        Zalogowany: {{ $store.state.username }}
      </div>
    </div>

    <KeyboardScanner
      :listen="true"
      @input="searchCylinder"
    ></KeyboardScanner>
  </div>
</template>
<script>
import KeyboardScanner from "@/components/KeyboardScanner.vue";
import UpdateNotifier from "@/components/UpdateNotifier.vue";

export default {
  name: "HomeHome",
  components: {
    KeyboardScanner,
    UpdateNotifier,
  },
  props: {
    msg: String,
  },
  computed: {
    user_id() {
      return this.$store.state.user_id;
    },
    productionTile: function () {
      return localStorage.getItem("production_tile") == 1 ? true : false;
    },
    legalizationTile: function () {
      return localStorage.getItem("legalization_tile") == 1 ? true : false;
    },
    cylinderaddTile: function () {
      return localStorage.getItem("cylinderadd_tile") == 1 ? true : false;
    },
    serviceTile: function () {
      return localStorage.getItem("service_tile") == 1 ? true : false;
    },
  },
  data: function () {
    return {
      navbar: true,
    };
  },
  methods: {
    searchCylinder: function (code) {
      this.$router.push("/cylinder/qrcode/" + code);
    },
  },
  watch: {
    navbar: function () {
      localStorage.setItem("navbar-app-home", this.navbar ? 1 : 0);
    },
  },
  mounted() {
    if (localStorage.getItem("navbar-app-home")) {
      this.navbar = localStorage.getItem("navbar-app-home") == 1 ? true : false;
    }
    if (!this.$store.state.user_id) {
      this.$router.push("/start/pin");
    }
    if (!this.$store.state.location_id) {
      this.$router.push("/settings");
    }
    if (!this.$store.state.hasInternet && this.$store.state.storage.orderApproveQuene.length != 0) {
      this.$router.push("/summary/nointernet");
    }
    this.$store.dispatch("storage/syncOrderApprove", this);
    if (this.$store.state.storage.productDictionary.length == 0 && this.$store.state.location_id) {
      this.$store.dispatch("storage/syncState").then(
        () => {
          this.$toast("Odświeżono");
        },
        () => {
          this.$toast.error("Brak połaczenia z serwerem");
        }
      );
    }
    // this.$store.dispatch('getKartaDrogowa')
    //this.getConfig();
    // if(! this.$auth.isAuthenticated()){
    //   alert('musisz sie zalogowac');
    // }else{
    //   alert('jestes zalogowany');
    // }
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.btn-menu {
  height: 130px;
}
</style>
