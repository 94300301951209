<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <div
      class="w-100 d-flex justify-content-between align-items-center"
      style="background: #dadada"
    >
      <router-link
        to="/service"
        class="btn btn-lg btn-secondary m-2"
        ><i class="bi bi-chevron-left"></i
      ></router-link>
      <h2 class="flex-fill m-0">Do wydania</h2>
    </div>
    <div
      v-if="loading"
      class="text-center"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div
      class="w-100 p-2 pb-5 container flex-grow-1 d-flex align-items-start flex-column justify-content-start text-start"
      style="overflow: auto"
    >
      <div class="w-100">
        <router-link
          v-for="(item, key) in last"
          :key="key"
          class="bg-body-tertiary border rounded-3 p-3 d-block mb-3 text-black"
          :to="'/service/show/' + item.service_requests_id"
        >
        <div class="row fw-bold mb-2 mb-sm-1">
            <div class="col-6 col-sm-4">Nr serwisu:</div>
            <div class="col-6 col-sm-8">{{item?.order.service_number}}/{{item?.order.service_year}}</div>
          </div>
          <div class="row  mb-2 mb-sm-1">
            <div class="col-6 col-sm-4">Nr butli:</div>
            <div class="col-6 col-sm-8">#{{ item.cylinder?.cylinder_qr_code }}</div>
          </div>

          <div class="row mb-2 mb-sm-1">
            <div class="col-12 col-sm-4">Klient:</div>
            <div class="col-12 col-sm-8">
              {{ item.customer?.nazwa }}
            </div>
          </div>

          <div class="row mb-2 mb-sm-1">
            <div class="col-6 col-sm-4 order-0 order-sm-0">Data dodania:</div>
            <div class="col-6 col-sm-8 order-2 order-sm-1">
              {{ item.created_at.date.substr(0, 16) }}
            </div>
          </div>

          <div class="row mb-2 mb-sm-1">
            <div class="col-6 col-sm-4">Status</div>
            <div class="col-6 col-sm-8">
              <ServiceStatusBadge :status="item.status"></ServiceStatusBadge>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import myApi from "@/api/myApi";
import ServiceStatusBadge from "@/components/service/ServiceStatusBadge.vue";

export default {
  name: "SimpleaddIndex",
  components: { ServiceStatusBadge },
  data: function () {
    return {
      selectedOrder: {},
      id: 0,
      products: [],
      filter: "",
      serial: "",
      date: "",
      loading: false,
      showAll: false,
      last: [],
    };
  },
  props: {},
  computed: {
    product: function () {
      return this.$store.state.simpleadd.product;
    },
  },
  methods: {},
  created() {},
  mounted() {
    const data = localStorage.getItem("last_simpleadd");
    if (data) {
      this.last = JSON.parse(data) ?? [];
    }

    myApi.getAllService({ status: "ready" }).then((response) => {
      this.last = response.data.data;
    });
    this.$store.dispatch("service/loadDictionary");
  },
};
</script>
